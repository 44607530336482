import React from "react";
import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        
         <div id="wrapper">
			<div id="head">
				<div id="header">
					<div id="navigation">
						<a id="logo" href="/"></a>
						  <div class="region region-search">
    <div id="block-search-form" class="block block-search">    
  <div class="content">
    <form action="/404" method="post" id="search-block-form" accept-charset="UTF-8"><div>      
</div></form>  </div>
</div>
  </div>
						<div class="meta">
							<a href="/faq-page/">
								FAQ
							</a>
							<a href="/glossar/">
								Glossar
							</a>
							<a href="/expertenrat/">
								Expertenrat
							</a>
							<a href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p9#etd=::00X,v9u?z00Y00aj00L00c?Z9W00Y00HO,WRvO00L00&amp;Z9TvZTV00Y00WV00L00vZn9vOcV?00Y00iannpB53poKKnnBB300ww$">
								Kontakt
							</a>
						</div>
						<div class="main">						
															<ul id="main-menu" class="links inline clearfix"><li class="menu-597 first"><a href="/wie-wirkt-imodium-akut/">Wie wirkt IMODIUM<sup>®</sup>?</a></li>
<li class="menu-690"><a href="/imodium-produkte/" title="">Produkte</a></li>
<li class="menu-727"><a href="/fakten-uber-durchfall/">Durchfall - Ursachen &amp; Behandlung</a></li>
<li class="menu-331"><a href="/diagnose-reizdarm/">Diagnose Reizdarm</a></li>
<li class="menu-627"><a href="/reisedurchfall-vorbeugen/">Durchfall auf Reisen</a></li>
<li class="menu-337"><a href="/durchfall-bei-kindern/">Durchfall bei Kindern</a></li>
<li class="menu-615 last"><a href="/infomaterial/" title="">Infomaterial</a><span class="info_icon"></span></li>
</ul>								
					</div>
					</div>
				</div>
			</div>
			<div id="content">
				<div class="sidebar">
															&nbsp;
				</div>
				<div class="content_c">
					  <div class="region region-content">
    <div id="block-system-main" class="block block-system">
    
  <div class="content">
        <h1><div class="field field-name-field-title-text field-type-text field-label-hidden"><div class="field-items"><div class="field-item even">404 Error</div></div></div></h1>
		<div class="std-site">
		
		<div div="" class="secondrow">
			<div class="left">
											</div>
			<div class="right">
				<div class="field field-name-body field-type-text-with-summary field-label-hidden"><div class="field-items"><div class="field-item even" property="content:encoded"><p>Leider konnten wir Ihre Seite nicht finden...</p>
<p>Die von Ihnen gesuchte Seite scheint momentan nicht verfügbar zu sein. Suchen Sie vielleicht eine der folgenden Seiten?</p>
<ul><li><a href="/">Startseite</a></li>
<li><a href="/fakten-uber-durchfall/">Fakten über Durchfall</a></li>
<li><a href="/richtig-essen/">Ernährung und Gesundheit</a></li>
<li><a href="/durchfall-wahrend-ihrer-periode/">Durchfall während Ihrer Periode</a></li>
<li><a href="/imodium-akut-schmelztabletten/">IMODIUM<sup>®</sup> akut Schmelztabletten</a></li>
</ul><p>Sie sind immer noch nicht fündig geworden? Dann verwenden Sie unsere <a href="">Suchfunktion</a>.</p>
</div></div></div>			</div>
			<div class="clear"></div>
		</div>
	</div>  </div>
</div>
  </div>
				</div>
				<div class="clear"></div>
			</div>
		</div>
      <Footer></Footer>                 
      <div id="disclaimer">
          <div className="wrap">
            <div className="region region-copyright">
              <div id="block-block-1" className="block block-block">
                <div className="content">
                  <p>
                    IMODIUM® ist ein Mittel zur symptomatischen Behandlung von
                    akutem Durchfall. Wirkstoff: Loperamid. Über Wirkung und
                    mögliche unerwünschte Wirkungen informieren
                    Gebrauchsinformation, Arzt oder Apotheker.
                  </p>
                  <p>
                    <br />© Johnson &amp; Johnson GmbH 2011-2019. Diese Website
                    wird von der Johnson &amp; Johnson GmbH veröffentlicht, die
                    allein für deren Inhalte verantwortlich ist. Sie ist für
                    Besucher aus Österreich bestimmt.
                    <br />
                    Letzte Aktualisierung am: 23. Dezember 2021, AT-IM-2100045
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="externlink">
          <div className="close modalClose" />
          <div className="hl">
            Sie verlassen jetzt
            <br />
            unsere Webseite
          </div>
          <p>
            Bitte beachten Sie, dass dieser Link eine Website öffnet, für deren
            Inhalt wir nicht verantwortlich sind und auf die unsere{" "}
            <a href="/datenschutz/">Datenschutzbestimmungen</a> keine Anwendung
            finden.
          </p>
          <div>
            <br />
            <a id="externlink_close" className="btn modalClose" href="#">
              <span className="start">Abbrechen</span>
              <span className="end" />
            </a>
            <a id="externlink_next" className="btn" href="#">
              <span className="start">Weiter</span>
              <span className="end" />
            </a>
            <div className="clear" />
          </div>
        </div>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n<!--//--><!--[CDATA[//--><!--\nfunction euCookieComplianceLoadScripts() {}\n//--><!--]]-->\n"
          }}
        />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              '\n<!--//--><!--[CDATA[//--><!--\nvar eu_cookie_compliance_cookie_name = "";\n//--><!--]]-->\n'
          }}
        />
        <script
          type="text/javascript"
          src="/sites/all/modules/contrib/eu_cookie_compliance/js/eu_cookie_compliance.js?qbta11"
        />
      </div>
    );
  }
}

export default Page;